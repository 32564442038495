.bottomMargin {
  margin-bottom: 10px !important;
}

.bottomSpace {
  margin-bottom: 30px !important;
}

.leftMargin {
  margin-left: 10px;
}

.centered {
  text-align: center;
}

.leftAlign {
  text-align: left;
}

.rightAlign {
  text-align: right;
}

.center {
  text-align: center;
}

.thickDivider {
  border: 3px solid;
}

.noEmptyTopBottom {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.alterBackgroundColor {
  background-color: lightgray;
}

.highlight {
  font-weight: bolder;
  color: rgb(26, 10, 207);
  border: 1px solid rgb(26, 10, 207);
  border-radius: 25%;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingSpinner {
  width: 20px;
  height: 20px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  margin-right: 20px;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.modalStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24;
  padding: 20px;  
}

.overrideDisabled {
  color: black !important;
}

.printOnly {
  display : none;
}

.gridRow {
  margin: 0;
  padding: 0;
}
.gridRowTextMiddle {
  margin: 0 0 0 5px;
  padding: 3px 0 0 0;
}

.fullWidth {
  width: 100%
}

.tournamentListFullRow {
  width: 100%;
  margin-left: 15px;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%
}

.flexRow {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media print
{    
    .noPrint, .noPrint *
    {
        display: none !important;
    }

    .printOnly {
      display : block;
   }
}